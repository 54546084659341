define("discourse/plugins/twitter-topic-group-button/discourse/templates/connectors/after-topic-footer-main-buttons/custom-public-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    class="btn topic-group-button"
    title={{this.siteSettings.topic_group_button_title}}
    type="button"
    {{action "clickButton"}}
  >
    {{this.siteSettings.topic_group_button_label}}
  </button>
  */
  {
    "id": "FGfxolJ3",
    "block": "[[[11,\"button\"],[24,0,\"btn topic-group-button\"],[16,\"title\",[30,0,[\"siteSettings\",\"topic_group_button_title\"]]],[24,4,\"button\"],[4,[38,0],[[30,0],\"clickButton\"],null],[12],[1,\"\\n  \"],[1,[30,0,[\"siteSettings\",\"topic_group_button_label\"]]],[1,\"\\n\"],[13]],[],false,[\"action\"]]",
    "moduleName": "discourse/plugins/twitter-topic-group-button/discourse/templates/connectors/after-topic-footer-main-buttons/custom-public-button.hbs",
    "isStrictMode": false
  });
});